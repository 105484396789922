import { ApiClient } from "../api-client";
import { AssignmentIdentity } from "../../entities-state/types";
import { map } from 'rxjs/operators';

export class ImmigrationEndpoint {
  constructor(private api: ApiClient) {
  }

  fetchImmigration = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/assignments/${assignmentId}/clients/${clientId}/immigrations`);
  };

  fetchCountriesList = () => {
    return this.api.get(`/assignments/immigrations/countries-list`);
  };

  fetchImmigrationTypes = () => {
    return this.api.get(`/assignments/immigrations/types`);
  };

  fetchImmigrationSubtype = (immigrationType: number) => {
    return this.api.get(`/assignments/immigrations/types/${immigrationType}/subtypes`);
  };

  updateImmigrationApplication = ({ clientId, assignmentId }: AssignmentIdentity, immigrationId: number, immigrationApplicationId: number, data: {}) => {
    return this.api
      .patch(`/assignments/${assignmentId}/clients/${clientId}/immigrations/${immigrationId}/applications/${immigrationApplicationId}`, data);
  };

  updateImmigrationApplicationMilestone = ({ clientId, assignmentId }: AssignmentIdentity, immigrationId: number, immigrationApplicationId: number, milestone: string) => {
    const data = { milestone: milestone };
    return this.api
      .patch(`/assignments/${assignmentId}/clients/${clientId}/immigrations/${immigrationId}/applications/${immigrationApplicationId}/milestones`, data);
  };

  fetchDocumentTypes = () => {
    return this.api.get('/assignments/immigrations/document_types').pipe(map(res => res.document_types));
  }

  fetchDocumentCategories = () => {
    return this.api.get('/assignments/immigrations/document_categories').pipe(map(res => res.document_categories));
  }

  sendDocumentsRequest = (
    assignmentId: number,
    serviceId: number,
    collectionId: string,
    instanceId: string,
    subsectionId: string,
    payload: { documents: { document_type_id: number, notes: string }[], sendRequestTo: string }
  ) => {
    return this.api.post(`/assignments/${assignmentId}/service/${serviceId}/collection/${collectionId}/instance/${instanceId}/subsection/${subsectionId}/documents`,
      payload);
  }

  fetchRequestedDocuments = (
    assignmentId: number,
    serviceId: number,
    collectionId: string,
    instanceId: string,
    subsectionId: string
  ) => {
    return this.api.get(`/assignments/${assignmentId}/service/${serviceId}/collection/${collectionId}/instance/${instanceId}/subsection/${subsectionId}/documents`)
      .pipe(map(res => res.documents))
  }

  requestDocumentLink = (
    assignmentId: number,
    serviceId: number,
    documentIdentifier?: string
  ) => {
    return this.api.get(`/assignments/${assignmentId}/service/${serviceId}/document-identifier/${documentIdentifier}/download-link`)
      .pipe(map(response => response.download_link));
  }
}
